/* Makes elements take 100% of height */
html,
body,
body > div:first-child,
div#__next,
div#__next > div {
  height: 100vh;
  height: -moz-available;          /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  height: stretch;
  width:100vw;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: visible;
  overscroll-behavior: contain;

  /* background-image: url('/smart-fit-images/WebAppBackground.svg');
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover; */

  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
  Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  color: #0070f3;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  display: block;
}